// import authBearer       from '@websanova/vue-auth/dist/drivers/auth/bearer.js';
import httpVueResource from '@websanova/vue-auth/drivers/http/vue-resource.1.x.js';
// import httpAxios from '@websanova/vue-auth/drivers/http/axios.1.x.js';
import routerVueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js';

var bearer = {
  request: function (req, token) {
    // console.log('req===', req);
    this.http.setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token
    });
  },
  response: function (res) {
    return res.body.accessToken;
  }
};

const config = {
    auth: bearer,
    http: httpVueResource,
    // http: httpAxios,
    router: routerVueRouter,
    rolesKey: 'role',
    authRedirect: '/',
    loginData:{
      url: 'authentication',
      redirect: '/admin',
      fetchUser: false,
      staySignedIn: true,
    },
    fetchData: {url: 'auth/user', method: 'GET', enabled: true},
    refreshData: { url: 'auth/refresh', method: 'GET', enabled: false, interval: 30 },
    parseUserData(res){
      if(!res.user.role){
        return null;
      }
      return res.user;
    }
  }


  export default config