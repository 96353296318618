<template>
  <div>
    <v-dialog v-model="show" width="500px" click:outside="onModalClose()">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Pick a location
        </v-card-title>
        <gmap-autocomplete class="introInput" @place_changed="setPlace">
          <template v-slot:input="slotProps">
            <v-text-field
              filled
              prepend-inner-icon="mdi-map-search"
              placeholder="Search here"
              ref="input"
              :hide-details="true"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs"
            >
            </v-text-field>
          </template>
        </gmap-autocomplete>
        <GmapMap
          ref="mapRef"
          :center="{ lat: 10, lng: 10 }"
          :zoom="7"
          style="width: 500px; height: 340px;"
        >
        </GmapMap>
        <div>
          <!-- <input
            id="pac-input"
            class="controls"
            type="text"
            placeholder="Search"
          /> -->

          <!-- <div id="map"></div> -->
        </div>

        <v-divider class="mt-0"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue lighten-5"
            class="primary--text text--darken-1 mr-2"
            elevation="0"
            large
            @click="cancel()"
            >Cancel</v-btn
          >
          <v-btn color="primary" elevation="0" large @click="done()" :disabled="!location || !location.lat"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {gmapApi} from 'vue2-google-maps-withscopedautocomp'
var google;

export default {
  components: {},
  inheritAttrs: false,
  name: "LocationPicker",
  props: {
    // show: {
    //   type: Boolean,
    // },
    rules: {
      type: [Object],
      description: "validation rules",
    },
  },
  data() {
    return {
      show: false,
      map: null,
      location: {},
      onDone: null,
      onCancel: null,
      geocoder: null,
      infoWindow: null,
      loading: false,
    };
  },
  watch: {},
  mounted() {
    gmapApi()
  },
  computed: {
    // google: gmapApi
  },
  methods: {
    done() {
      this.onDone && this.onDone(this.location);
    },
    cancel() {
      this.show = false;
      this.onModalClose();
      this.onCancel && this.onCancel();
    },
    close(){
      this.show = false;
      this.onModalClose();
    },
    onModalClose(){
      this.infoWindow && this.infoWindow.close();
    },
    setPlace(place) {
      this.location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.gotoLocation();
    },
    pick(iniLocation = null, onDone, onCancel) {
      this.show = true;
      this.loading = true;
      //delay for the modal to load first
      setTimeout(() => {
        this.initializeMap().then(()=>{
          if (iniLocation) this.location = { ...iniLocation };
          this.gotoLocation();
          this.loading = false;
        });
      }, 50);
      this.onDone = onDone;
      this.onCancel = onCancel;
    },
    async initializeMap() {
      if(!this.map){
        this.map = await this.$refs.mapRef.$mapPromise
      }
      if(!google){
        google = gmapApi();
      }
      console.log('google', google);
      const map = this.map;
      var myLatlng = { lat: -25.363, lng: 131.044 };
      // this.map = new google.maps.Map(document.getElementById("map"), {
      //   zoom: 4,
      //   center: myLatlng,
      // });
      this.geocoder = new google.maps.Geocoder();
      // Create the initial InfoWindow.
      this.infoWindow = new google.maps.InfoWindow({
        content: "Click the map to get Lat/Lng!",
        position: myLatlng,
      });
      this.infoWindow.open(map);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (!this.location || !this.location.lat) {
              this.location = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              ///getCurrentPosition ==
              this.gotoLocation();
            }
          },
          (err) => {
            handleLocationError(err, this.infoWindow, map.getCenter());
          }
        );
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(
          `Browser doesn't support Geolocation`,
          this.infoWindow,
          map.getCenter()
        );
      }
      // Configure the click listener.
      map.addListener("click", (mapsMouseEvent) => {
        // Close the current InfoWindow.
        this.infoWindow.close();
        // Create a new InfoWindow.
        this.infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });
        this.infoWindow.setContent(mapsMouseEvent.latLng.toString());
        this.infoWindow.open(map);
        this.location = {
          lat: mapsMouseEvent.latLng.lat(),
          lng: mapsMouseEvent.latLng.lng(),
        };
      });
      // Create the search box and link it to the UI element.
      this.initSearchBox(map);
    },
    geocodeLatLng(latlng) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              let trans = this.translateGeocoderResults(results);
              trans.formatted_address = results[0].formatted_address;
              resolve(trans);
            } else {
              reject("No results found");
              console.error("No results found");
            }
          } else {
            reject("Geocoder failed due to: " + status);
            console.error("Geocoder failed due to: " + status);
          }
        });
      });
    },
    gotoLocation() {
      console.log('going to location==');
      this.infoWindow.close();
      this.infoWindow = new google.maps.InfoWindow({
        position: this.location,
      });
      this.infoWindow.setContent(this.location.lat + ", " + this.location.lng);
      this.infoWindow.open(this.map);
      this.map.setCenter(this.location);
      this.map.setZoom(14);
    },
    translateGeocoderResults(results) {
      var addr = {};
      for (var ii = 0; ii < results[0].address_components.length; ii++) {
        // var street_number = (route = street = city = state = zipcode = country = formatted_address =
        //   "");
        // let street_number = "";
        let city = "";
        var types = results[0].address_components[ii].types.join(",");
        if (types == "street_number") {
          addr.street_number = results[0].address_components[ii].long_name;
        }
        if (types == "route" || types == "point_of_interest,establishment") {
          addr.route = results[0].address_components[ii].long_name;
        }
        if (
          types == "sublocality,political" ||
          types == "locality,political" ||
          types == "neighborhood,political" ||
          types == "administrative_area_level_3,political"
        ) {
          addr.city =
            city == "" || types == "locality,political"
              ? results[0].address_components[ii].long_name
              : city;
        }
        if (types == "administrative_area_level_1,political") {
          addr.state = results[0].address_components[ii].short_name;
        }
        if (
          types == "postal_code" ||
          types == "postal_code_prefix,postal_code"
        ) {
          addr.zipcode = results[0].address_components[ii].long_name;
        }
        if (types == "country,political") {
          addr.country = results[0].address_components[ii].long_name;
        }
      }
      return addr;
    },
    initSearchBox(map) {
      const input = document.getElementById("pac-input");
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });
      let markers = [];
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }
        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();
        let firstLocation = null;
        places.forEach((place) => {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }
          // const icon = {
          //   url: place.icon,
          //   size: new google.maps.Size(71, 71),
          //   origin: new google.maps.Point(0, 0),
          //   anchor: new google.maps.Point(17, 34),
          //   scaledSize: new google.maps.Size(25, 25)
          // };
          // Create a marker for each place.
          // markers.push(
          //   new google.maps.Marker({
          //     map,
          //     icon,
          //     title: place.name,
          //     position: place.geometry.location
          //   })
          // );
          firstLocation = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
        if (firstLocation) {
          this.location = firstLocation;
          this.infoWindow.close();

          // Create a new InfoWindow.
          this.infoWindow = new google.maps.InfoWindow({
            position: this.location,
          });
          // this.infoWindow.setPosition(this.location);
          this.infoWindow.setContent(
            this.location.lat + ", " + this.location.lng
          );
          this.infoWindow.open(map);
        }
      });
    },
  },
};
function handleLocationError(err /*infoWindow, location*/) {
  console.log("map error", err);
}
</script>
<style>
#map {
  height: 70vh;
}

#pac-input {
  left: 0px !important;
  top: 55px !important;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 8px 13px;
  text-overflow: ellipsis;
  width: 247px;
  border: 1px solid var(--primary);
}

#pac-input:focus {
  border-color: #4d90fe;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

.pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
  z-index: 9999;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}
</style>
