
<template>
  <side-form-sheet ref="formSheet" :title="`Change Pin for ${model.firstName} ${model.lastName}`" :buttonsOnfooter="false">
    <v-container>
      <v-row>
        
        <v-col cols="12" md="6" v-if="model.id">
          <v-text-field filled dense rounded v-model="model.password" type="password" :rules="passRules" label="Pin"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="model.id">
          <v-text-field filled dense rounded v-model="model.confirmPassword" type="password" :rules="confirmPassRules" label="Confirm Pin"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </side-form-sheet>
</template>

<script>
import SideFormSheet from "../../../../../components/SideFormSheet.vue";
import { mapGetters,  mapActions} from 'vuex'
export default {
  name: "ChangePin",
  components: {
    SideFormSheet,
  },
  data() {
    return {
      valid: false,
      formDrawer: true,
      requiredRules: [(v) => !!v || "Field is required"],
      passRules: [
        (v) => !!v || "Field is required",
        (v) =>
          (v && v.length == 4) || "Password must be 4 characters",
      ],
      confirmPassRules: [
        (v) => this.model.password == v || "Password mismatch",
        (v) => !!v || "Field is required",
      ],
      model: {},
      serverErrors: {},
      roles: [],
    };
  },
  props: {},
  computed:{
    ...mapGetters(['formResources'])
  },
  mounted() {
    this.loadFormResources(['userTypes', 'facilities']);
  },
  methods: {
    ...mapActions(['loadFormResources']),
    show(model, onSave, onCancel = null) {
      if(!model.facilities)
        model.facilities = [];
      this.model = { ...model };
      this.$refs.formSheet.show(() => {
        onSave(this.model);
      }, onCancel);
    },
    close(){
      this.$refs.formSheet.close();
    },
    load(loading = true) {
      this.$refs.formSheet.load(loading);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

