<template>
  <v-app
    id="inspire"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
  <facility-form ref="facilityForm"></facility-form>
  <user-account-form ref="userAccountForm"></user-account-form>
    <notification-form ref="notificationForm"></notification-form>

  
  
    <v-navigation-drawer
      class="admin-sidenav"
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="70"
      width="270"
      :permanent="$vuetify.breakpoint.mdAndUp"
      app
      color="#1e1e2d"
    >
      <v-list-item class="mt-4">
        <!-- <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>-->

        <v-list-item-title v-if="!mini">
          <h2 class="pl-3">{{appName}}</h2>
        </v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-{{ !mini ? "left" : "right" }}</v-icon>
        </v-btn>
      </v-list-item>

      <!-- <v-divider></v-divider> -->

      <v-list dense nav class="px-3">
        <template v-for="node in items">
          <v-list-item
            :key="node.title"
            link
            :to="node.link"
            v-if="!node.children"
            color="primary"
          >
            <v-list-item class="px-0 py-3">
              <v-icon :color="node.color" class="mr-2">{{ node.icon }}</v-icon>
              <v-list-item-title class="body-2">{{
                node.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item>

          <v-list-group
            v-else
            :value="$route.path.includes(node.link)"
            no-action
            :key="node.title"
            class="px-0"
            append-icon="mdi-chevron-down"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item class="px-0 py-3">
                  <v-icon :color="node.color" class="mr-2">{{
                    node.icon
                  }}</v-icon>
                  <v-list-item-title class="mt-1 body-2">{{
                    node.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(childNode, i) in node.children"
              :key="i"
              link
              :to="childNode.link"
              style="padding-left: 40px;"
            >
              <v-list-item
                class="body-2 text-nowrap"
                v-text="childNode.title"
              ></v-list-item>
              <v-list-item-icon>
                <v-icon v-text="childNode.icon" small></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>


    <v-app-bar
      flat
      app
      light
      :style="{ background: $vuetify.theme.themes[theme].background }"
    >
      <div class="d-flex d-md-none align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-3"></v-app-bar-nav-icon>
        <h2 class="primary--text">{{appName}}</h2>
      </div>

      <v-breadcrumbs :items="breadcrumbs" class="d-none d-md-block">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <v-spacer></v-spacer>

      <div class="d-none d-md-block">
        <v-btn class="primary--text text--darken-1 mr-2 text-transform-none"  elevation="0" @click="newUserAccount()">
          <v-icon class="mr-1">mdi-account-key</v-icon>
          <span>New User</span>
        </v-btn>
        <v-btn color="primary" dark class="mr-5 text-transform-none" elevation="0" @click="newFacility()">
          <v-icon class="mr-1">mdi-hospital-building</v-icon>
          <span>New Facility</span>
        </v-btn>
      </div>

      <v-btn elevation="0" @click="profileDrawer = true" tile>
        <span class="grey--text">Hi,</span>
        <span class="mr-2 subtitle-2" style="text-transform: none;">{{currentUser.firstName}}</span>
        <v-avatar color="cyan lighten-4" rounded size="30" class="cyan--text text--darken-3" >{{ initial }}</v-avatar>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <v-navigation-drawer
      v-model="profileDrawer"
      fixed
      temporary
      right
      :light="true"
      width="400"
      :overlay-opacity="0.1"
    >
      <div class="pa-3">
        <v-row align="center">
          <v-card-title>User Profile</v-card-title>
          <!-- <span class="grey--text text-caption">12 messages</span> -->
          <v-spacer></v-spacer>
          <v-btn elevation="0" small icon>
            <v-icon light color="grey">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-row>
        <v-row class="pl-3 mt-4">
          <v-avatar color="primary" rounded size="110" class="mr-2">
            <v-img src="@/assets/black_man_profile.png"></v-img>

          </v-avatar>
          <v-col class="pt-0">
            <div class="h6">{{ fullName }}</div>
            <div class="body-2 grey--text mt-1">{{ currentUser.role }}</div>
            <div class="body-2 grey--text mt-1">
              <v-icon color="info">mdi-email</v-icon>
              {{ currentUser.email }}
            </div>
            <v-btn
              class="primary--text text--darken-1 mt-2"
              color="blue lighten-5"
              elevation="0"
              @click="$auth.logout({ redirect: { path: '/' } })"
              >Sign Out</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import FacilityForm from '../pages/admin/facilities/_components/FacilityForm.vue';
import UserAccountForm from '../pages/admin/user-accounts/_components/UserAccountForm.vue';
import NotificationForm from '../pages/admin/notifications/_components/NotificationForm.vue';

export default {
  components: { FacilityForm, UserAccountForm,NotificationForm},
  data: () => ({
    drawer: null,
    profileDrawer: false,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-monitor-dashboard",
        link: "/admin/dashboard",
        color: "",
      },
      { title: "Referral Cases", icon: "mdi-emoticon-sick-outline", link: "/admin/referrals", color: "", },
      { title: "Referral Entries", icon: "mdi-cellphone-wireless", link: "/admin/referral-entries", color: "" },
      { title: "Facilities", icon: "mdi-hospital-building", link: "/admin/facilities", color: "", },
      { title: "Users", icon: "mdi-account-key", link: "/admin/users", color: "", },
      { title: "Admin Accounts", icon: "mdi-account-group-outline", link: "/admin/accounts", color: "", },
      { title: "Notification Management", icon: "mdi-bell", link: "/admin/notifications", color: "", },

      { title: "Settings", icon: "mdi-cog", link: "/admin/settings", color: "", },
    ],
    mini: false,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    breadcrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          href: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: capitalizeFirstLetter(
            (this.$route.matched[idx]? (this.$route.matched[idx].meta? this.$route.matched[idx].meta.breadCrumb :null) ||
              this.$route.matched[idx].title :null ) ||
              path
          ),
          disabled: idx == pathArray.length - 1,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
    currentUser() {
      return this.$auth.user() ? this.$auth.user() : {};
    },
    initial() {
      return this.$auth.user() ? this.$auth.user().firstName.charAt(0) : "";
    },
    fullName() {
      return this.$auth.user()
        ? this.$auth.user().firstName + " " + this.$auth.user().lastName
        : null;
    },
    appName(){
      return process.env.VUE_APP_NAME;
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods:{
    newUserAccount(){
      this.$refs.userAccountForm.show({}, (data)=>{
        this.$refs.userAccountForm.load();
        this.$http.post('users', data)
        .then(()=>{
          this.loadTableData()
          this.$refs.userAccountForm.load(false);
          this.$refs.userAccountForm.close();
          this.$toast.success('Account added')
        }).catch(error=>{
          this.$toast.error(error.body.message)
          this.$refs.userAccountForm.load(false);
        })
      })
    },
    newFacility(){
      this.$refs.facilityForm.show({}, (data)=>{
        this.$refs.facilityForm.load();
        this.$http.post('facilities', data)
        .then(()=>{
          this.loadTableData()
          this.$refs.facilityForm.load(false);
          this.$refs.facilityForm.close();
          this.$toast.success('Account added')
        }).catch(error=>{
          this.$toast.error(error.body.message)
          this.$refs.facilityForm.load(false);
        })
      })
    },

     newNotification(){
      this.$refs.notificationForm.show({}, (data)=>{
        this.$refs.notificationForm.load();
        this.$http.post('facilities', data)
        .then(()=>{
          this.loadTableData()
          this.$refs.notificationForm.load(false);
          this.$refs.notificationForm.close();
          this.$toast.success('Account added')
        }).catch(error=>{
          this.$toast.error(error.body.message)
          this.$refs.notificationForm.load(false);
        })
      })
    },

    
  }
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
</script>
<style lang="scss">
.admin-sidenav {
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #a2a3b7 !important;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    .v-icon {
    color: #8083ad !important;
  }
  // $deg: 180deg;
  // background: #348F50;  /* fallback for old browsers */
  // background: -webkit-linear-gradient( $deg, #56B4D3, #348F50) !important;  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient($deg, #56B4D3, #348F50) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
