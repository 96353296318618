import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './plugins/store'
import vuetify from './plugins/vuetify';
import auth from '@websanova/vue-auth';
import authConfig from './auth'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VuetifyConfirm from 'vuetify-confirm'
import "./scss/custom.scss";
//landing imports
var VueScrollTo = require('vue-scrollto');
import VueYoutube from 'vue-youtube'
import Scrollspy from 'vue2-scrollspy';
import './plugins/map';

Vue.config.productionTip = false

Vue.router = router
import './http';
Vue.use(auth, authConfig)
const options = {
  // You can set your default options here
};
Vue.use(Toast, options);
Vue.use(VuetifyConfirm, { vuetify })

/**landing */
Vue.use(VueScrollTo)
Vue.use(VueYoutube)
Vue.use(Scrollspy);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
