<template>
  <side-form-sheet ref="formSheet" :title="model.id? 'Edit Notification': 'Create New Notification'">
    <change-notification ref="changeNotification"></change-notification>
    <v-container>
      <v-row>
        <!-- <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.firstName" :rules="requiredRules" label="First name" required ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.lastName" filled dense rounded :rules="requiredRules" label="Last name" required ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select filled dense rounded v-model="model.gender" :rules="requiredRules" label="Gender" item-text="label" :items="['male', 'female']" ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.email" filled dense rounded :rules="emailRules" label="Email"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.phone" :rules="phoneRules" label="Phone"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.profession" :rules="requiredRules" label="Profession"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.dob" type="date" label="Date of birth"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.address" label="Address"></v-text-field>
        </v-col>
       
          <v-select filled dense rounded v-model="model.profession" :rules="requiredRules" label="Profession" item-text="label" item-value="id" :items="formResources.professions" ></v-select>
        </v-col> 

         <v-col cols="12" md="6" v-if="!model.id">
          <v-text-field filled dense rounded v-model="model.confirmPassword" type="text" :rules="confirmPassRules" label="Val"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select filled dense rounded v-model="model.userType" :rules="requiredRules" label="Role" item-text="label" item-value="id" :items="formResources.userTypes" ></v-select>
        </v-col>
        <v-col cols="12" md="12" v-if="model.userType=='doctor'">
          <v-select filled dense rounded v-model="model.facilityIds" :rules="requiredRules" label="Doctor's Facilities" chips multiple item-text="name" item-value="id" :items="formResources.facilities" hint="What are his facilities"></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="!model.id">
          <v-text-field filled dense rounded v-model="model.password" type="password" :rules="passRules" label="Pin"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="!model.id">
          <v-text-field filled dense rounded v-model="model.confirmPassword" type="password" :rules="confirmPassRules" label="Confirm Pin"></v-text-field>
        </v-col> -->

        <!-- <v-col cols="12" md="6" v-if="!model.status">
          <v-text-field filled dense rounded v-model="model.status" type="text"  label="Status"></v-text-field>
        </v-col> -->
        <v-col cols="12" md="6" v-if="!model.id" >
          <v-text-field filled dense rounded v-model="model.campaign" type="text"  label="Campaign"></v-text-field>
        </v-col>


        <v-col cols="12" md="6"  v-if="!model.id" >
                    <v-select filled dense rounded v-model="model.target"  label="Target" item-text="label" :items="['all','ios', 'android']" ></v-select>

          <!-- <v-text-field filled dense rounded v-model="model.target" type="text"  label="Target"></v-text-field> -->
        </v-col>
<!-- 
        <v-col cols="12" md="6" v-if="!model.click_opens">
          <v-text-field filled dense rounded v-model="model.click_opens" type="text"  label="Click/Opens"></v-text-field>
        </v-col> -->

        <v-col cols="12 mt-5" md="12" v-if="model.id">
            <v-btn color="warning" elevation="0" large @click="changeNotification()" class="mt-1 mr-2"> <v-icon class="mr-2">mdi-lock-alert-outline</v-icon> Change Notificaiton</v-btn>
        </v-col>

      </v-row>
    </v-container>
  </side-form-sheet>
</template>

<script>
import SideFormSheet from "../../../../../components/SideFormSheet.vue";
import { mapGetters,  mapActions} from 'vuex'
import ChangeNotification from './ChangeNotification.vue';
export default {
  name: "NotificationForm",
  components: {
    SideFormSheet,
    ChangeNotification,
  },
  data() {
   
    return {
      valid: false,
      formDrawer: true,
      requiredRules: [(v) => !!v || "Field is required"],
      campaignRules: [
        (v) => !!v || "Field is required",
        //(v) => v && v.length>9
      ],
      //  statusRules: [
      //   (v) => !!v || "Field is required",
      
      // ],
      //  clickOpensRules: [
      //   (v) => !!v || "Field is required",
      
      // ],
       targetRules: [
        (v) => !!v || "Field is required",
      
      ],
      // confirmPassRules: [
      //   (v) => this.model.password == v || "Password mismatch",
      //   (v) => !!v || "Field is required",
      // ],
      // emailRules: [
      //   (v) => !!v || "Field is required",
      //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      // ],
      // phoneRules: [
      //   (v) => !!v || "Field is required",
      //   (v) => v && v.length>9 || "Phone number must be valid",
      // ],
      model: {},
      serverErrors: {},
      roles: [],
    };
  },
  props: {},
  computed:{
    ...mapGetters(['formResources'])
  },
  mounted() {
    //this.loadFormResources(['userTypes', 'facilities']);
  },
  methods: {
    ...mapActions(['loadFormResources']),
    changeNotification(){

      
    console.log("sending notifications");
      //------------

      
      this.$refs.changeNotification.show(this.model, (data)=>{
        this.$refs.changeNotification.load();
        this.$http.post('notifications', {campaign: data.campaign, target: this.model.target})
        .then(()=>{
          this.$refs.changeNotification.load(false);
          this.$refs.changeNotification.close();
          this.$toast.success('Nofification Sent')
        }).catch(error=>{
          this.$toast.error(error.body.message)
          this.$refs.changeNotification.load(false);
        })
      });
    },
    show(model, onSave, onCancel = null) {
      if(!model.notifications)
        model.notifications = [];
      this.model = { ...model };
      this.$refs.formSheet.show(() => {
        onSave(this.model);
      }, onCancel);
    },
    close(){
      this.$refs.formSheet.close();
    },
    load(loading = true) {
      this.$refs.formSheet.load(loading);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

