import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminLayout from "../views/layouts/AdminLayout";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: 'auth/admin'
    // component: () => import(/* webpackChunkName: "admin" */ "../views/pages/landing/Index.vue"),
  },
  {
    path: "/auth/admin",
    component: () => import(/* webpackChunkName: "admin" */ "../views/pages/auth/Admin.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: 'admin/dashboard',
    component: AdminLayout,
    meta: { auth: true },
    children:[
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/dashboard/Index.vue"),
      },
      {
        path: "referrals",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/referrals/Index.vue")
      },
      {
        path: "referral-entries",
        meta: {breadCrumb: "Referral Entries"},
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/referral-entries/Index.vue")
      },
      {
        path: "facilities",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/facilities/Index.vue")
      },
      {
        path: "users",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/user-accounts/Index.vue"),
      },
      {
        path: "accounts",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/admin-accounts/Index.vue"),
      },
      {
        path: "notifications",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/notifications/Index.vue"),
      },
      {
        path: "settings",
        component: () => import(/* webpackChunkName: "admin" */ "../views/pages/admin/settings/Index.vue"),
      },
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/About.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
