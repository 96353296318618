<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
  body{
    // color: #3F4254;
  }
</style>
