import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    formResources: {}
  },
  getters: {
    formResources:(state)=>state.formResources,
  },
  mutations: {
    setFormResources (state, resources) {
      state.formResources = {...state.formResources, ...resources}
    }
  },
  actions: {
    loadFormResources (context, resourceTypes=[]) {
      return new Promise((resolve, reject) => {
        let emptyResources= [];
        for (const rt of resourceTypes) {
          if(!context.state.formResources[rt] || !context.state.formResources[rt].length)
            emptyResources.push(rt);
        }
        if(emptyResources.length){
          this._vm.$http.get(`form-resources`, {params: {resourceKeys: emptyResources, $companyUrl:'ops'} }).then(
            (res) => {
              const resources = emptyResources.reduce((accum, cRt)=>{ 
                accum[cRt]=res.body[cRt] 
                return accum;
              }, {})
              context.commit('setFormResources', resources)
              resolve();
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        }
      })
    },
    // end of loadFormResources


  }
})
export default store;