import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    options:{
        customProperties: true
    },
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                background: '#F3F4F9',
                primary: '#2196F3'
            },
            dark: {
                // primary: 'green',
            },
        },
    },
});
