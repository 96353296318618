import debounce from 'lodash/debounce';

export default function searcher(vm, url, options={}) {
    const opts = {
        params:{}, initialResult:{}, initialLoad:false,
        ...options
    }
    var sr = {loading: false, result: opts.initialResult};
    sr.search = debounce((search, _param={}) => {
        sr.loading =true;
        vm.$http.get(url, {
            params: {...opts.params, ..._param, $search: search}
        }).then(res => {
            sr.result= res.body;
        }).finally(()=>{sr.loading =false;})
    })
    sr.oneTimeLoad=(url, params={})=>{
        vm.$http.get(url, { params }).then(res => {
            sr.result= res.body;
        }).finally(()=>{sr.loading =false;})
    }
    if(opts.initialLoad)
        sr.search('')
    return sr
}