import Vue from 'vue'
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_API_URL;
Vue.http.interceptors.push(
    (request) => {
        var _domain = domain();
        request.headers.set('X-Eyesight-Domain', _domain)
        request.headers.set('X-Rds-App-key', process.env.VUE_APP_API_KEY)

  
        return function(response) {
            if(response.body.rds_stat)
              response.status = Number(response.body.rds_stat)
            // if(response.status== 114)
            //   response.status=401
            // if(![200, 201].includes(response.status)){
            //   if(response.body)
            //     response.message= response.body.rds_resp
            //   response.ok =false;
            // }
        };
    }
)
function domain() {
    const spl = window.location.host.split('.')
    return spl.length>2? spl[0]: null;
}

