
<template>
  <v-navigation-drawer v-model="formDrawer" fixed temporary :overlay-opacity=".1" right width="600">
    <template v-slot:prepend>
      <v-toolbar flat>
        <h4 class="blue-grey--text text--darken-2 subtitle-1">{{title}}</h4>
        <v-spacer></v-spacer>
      </v-toolbar>
    <!-- <v-divider></v-divider> -->
    </template>
    <div class="pa-3">
      <v-form ref="form">
        <slot></slot>
      </v-form>
      <v-toolbar flat v-if="!buttonsOnfooter && showButtons" class="mt-8">
        <v-btn
          v-if="showDelete"
          color="red lighten-5"
          class="red--text text--darken-1 mr-2"
          elevation="0"
          large
          @click="deleteClick()"
        >Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue lighten-5"
          class="primary--text text--darken-1 mr-2"
          elevation="0"
          large
          @click="cancel()"
        >Cancel</v-btn>
        <v-btn color="primary" elevation="0" large @click="save()">Save</v-btn>
      </v-toolbar>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-slot:append>
      <v-toolbar flat v-if="buttonsOnfooter && showButtons">
        <v-btn
          v-if="showDelete"
          color="red lighten-5"
          class="red--text text--darken-1 mr-2"
          elevation="0"
          large
          @click="deleteClick()"
        >Delete</v-btn>
        
        <v-spacer></v-spacer>
        
        <v-btn
          color="blue lighten-5"
          class="primary--text text--darken-1 mr-2"
          elevation="0"
          large
          @click="cancel()"
        >Cancel</v-btn>

        <v-btn color="primary" elevation="0" large @click="save()">Save</v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideFormSheet",
  data() {
    return {
      formDrawer: false,
      onSave: null,
      onCancel: null,
      onDelete: null,
      loading: false,
    };
  },
  props: {
    title: { default: "Title" },
    buttonsOnfooter:{ default: true},
    showButtons:{ default: true},
    showDelete:{ default: false}
  },
  computed:{
    form(){
      return this.$refs.form;
    }

  },
  methods: {
    show(onSave, onCancel = null, onDelete = null) {
      this.$refs.form.resetValidation();
      this.formDrawer = true;
      this.onSave = onSave;
      this.onCancel = onCancel;
      this.onDelete = onDelete;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.onSave && this.onSave();
        this.$emit("save");
      }
    },
    validate(){
      return this.$refs.form.validate();
    },
    cancel() {
      this.formDrawer = false;
      this.onCancel && this.onCancel();
      this.$emit("cancel");
    },
    deleteClick() {
      this.onDelete && this.onDelete();
      this.$emit("delete");
    },
    close() {
      this.formDrawer = false;
    },
    load(load = true) {
      this.loading = load;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

