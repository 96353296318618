<template>
  <side-form-sheet ref="formSheet" :title="model.id? 'Edit Facility': 'Create New Facility'">
    <location-picker ref="locationPicker"></location-picker>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" v-if="model.id">
          <h3 class="ma-0"><span class="grey--text">Code: </span> <span>#{{model.id}}</span> </h3>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field filled dense rounded v-model="model.name" :rules="requiredRules" label="Name" required ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea v-model="model.description" rows="2" filled dense rounded :rules="requiredRules" label="Description" required ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.contactEmail" filled dense rounded :rules="emailRules" label="Contact Email"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.contactPhone" :rules="requiredRules" label="Contact Phone"></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-select filled dense rounded v-model="model.facilityTypes" :rules="requiredRules" multiple chips label="Facility Types" item-text="label" item-value="id" :items="formResources.facilityTypes" ></v-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete clearable filled dense rounded v-model="model.districtId" :rules="requiredRules" label="District.... type to auto populate"  item-text="name" item-value="id"
            :items="districtsSearcher.result.data" :loading="districtsSearcher.loading"
            :search-input.sync="districtsSearcher.searchText" hide-no-data hide-selected
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <div class="d-flex">
            <v-btn color="primary" elevation="0" large @click="pickLocation()" class="mt-1 mr-2"> <v-icon class="mr-2">mdi-map-search</v-icon> Pick Location</v-btn>
            <v-text-field disabled filled dense rounded v-model="model.locationName" :rules="requiredRules" label="Location Name" required ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </side-form-sheet>
</template>

<script>
import SideFormSheet from "../../../../../components/SideFormSheet.vue";
import { mapGetters,  mapActions} from 'vuex'
import LocationPicker from '@/components/LocationPicker.vue';
import searcher from '@/utils/searcher'
export default {
  name: "facility-form",
  components: {
    SideFormSheet,
    LocationPicker,
  },
  data() {
    return {
      valid: false,
      formDrawer: true,
      districtsSearcher: searcher(this, 'districts', {initialLoad:true, initialResult:{data:[]}}),
      requiredRules: [(v) => !!v || "Field is required"],
      passRules: [
        (v) => !!v || "Field is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
      ],
      confirmPassRules: [
        (v) => this.model.password == v || "Password mismatch",
        (v) => !!v || "Field is required",
      ],
      emailRules: [
        (v) => !!v || "Field is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      model: {},
      serverErrors: {},
      roles: [],
    };
  },
  props: {},
  watch:{
    "districtsSearcher.searchText":function(searchText){ 
      this.districtsSearcher.search(searchText)
    }
  },
  computed:{
    ...mapGetters(['formResources'])
  },
  mounted() {
    this.loadFormResources(['facilityTypes']);
  },
  methods: {
    ...mapActions(['loadFormResources']),
    pickLocation(){
      this.$refs.locationPicker.pick(this.model.lat? {lat: this.model.lat, lng: this.model.lng}: null, (loc) => {
        this.model.lat = loc.lat;
        this.model.lng = loc.lng;
        this.load() 
        this.$refs.locationPicker.geocodeLatLng(loc).then(address => {
          this.model.locationName = address.formatted_address;
          this.model = { ...this.model };
        }).finally(()=>{this.load(false) });
        this.$refs.locationPicker.close();
      });
    },
    show(model, onSave, onCancel = null) {
      this.model = { ...model };
      if(model.districtId){
        this.districtsSearcher.oneTimeLoad('districts', {id: model.districtId})
      }
      this.$refs.formSheet.show(() => {
        onSave(this.model);
      }, onCancel);
    },
    close(){
      this.$refs.formSheet.close();
    },
    load(loading = true) {
      this.$refs.formSheet.load(loading);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

